import { render, staticRenderFns } from "./PublicationSummary.vue?vue&type=template&id=3e548922&scoped=true&"
import script from "./PublicationSummary.vue?vue&type=script&lang=ts&"
export * from "./PublicationSummary.vue?vue&type=script&lang=ts&"
import style0 from "./PublicationSummary.vue?vue&type=style&index=0&id=3e548922&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e548922",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PostCover: require('/tmp/build_6043b71c/apps/web/components/post/PostCover.vue').default,Author: require('/tmp/build_6043b71c/apps/web/components/author/Author.vue').default,SubscribeButton: require('/tmp/build_6043b71c/apps/web/components/buttons/SubscribeButton.vue').default,PostLikeButton: require('/tmp/build_6043b71c/apps/web/components/post/PostLikeButton.vue').default,Share: require('/tmp/build_6043b71c/apps/web/components/share.vue').default,ContactAuthorButton: require('/tmp/build_6043b71c/apps/web/components/buttons/ContactAuthorButton.vue').default})
