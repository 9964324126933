import { render, staticRenderFns } from "./Author.vue?vue&type=template&id=5026e38a&"
import script from "./Author.vue?vue&type=script&lang=ts&"
export * from "./Author.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KImage: require('/tmp/build_6043b71c/apps/web/components/image/KImage.vue').default,PostCounter: require('/tmp/build_6043b71c/apps/web/components/post/PostCounter.vue').default})
